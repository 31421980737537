"use strict";

import { FullStory, init } from "@fullstory/browser";

const FullStoryPort = {
  add: function (app, orgId) {
    if (orgId) {
      init({ orgId: orgId });
      window["_fs_run_in_iframe"] = true;

      app.ports.fullStoryPortBoot.subscribe(function (userIdAndUserAttrs) {
        const userId = userIdAndUserAttrs[0];
        const userAttrs = userIdAndUserAttrs[1];

        FullStory("setIdentity", {
          uid: userId,
          properties: userAttrs,
        });
      });
    }
  },
};

module.exports = FullStoryPort;
