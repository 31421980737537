"use strict";

function ckEditorConfig() {
  return {
    language: "en",
    uiColor: "#FAFAFA",
    width: "100%",
    toolbar: "full",
    fillEmptyBlocks: false,
    toolbar_full: [
      {
        name: "basicstyles",
        items: [
          "Bold",
          "Italic",
          "Underline",
          "Strike",
          "-",
          "CopyFormatting",
          "RemoveFormat",
        ],
      },
      { name: "links", items: ["Link"] },
      {
        name: "paragraph",
        items: [
          "NumberedList",
          "BulletedList",
          "-",
          "Outdent",
          "Indent",
          "-",
          "Blockquote",
          "-",
          "JustifyLeft",
          "JustifyCenter",
          "JustifyRight",
          "JustifyBlock",
        ],
      },
      "/",
      { name: "styles", items: ["Format", "Font", "FontSize"] },
      { name: "colors", items: ["TextColor", "BGColor"] },
    ],
  };
}

function ckEditorNotificationsConfig() {
  let config = ckEditorConfig();

  config.contentsCss = CKEDITOR.getUrl("notifications-layout-042120211500.css");

  return config;
}

function sanitizeText(text) {
  // Marked parses any markdown content and solves the blank-space removal issue adding paragraphs.
  return marked(clearIndentation(text));
}

// Markdown parser recognize indentation as a pre-formatted text which breaks the email template
//  when using lists.
function clearIndentation(text) {
  return text.replaceAll(/\t/g, "");
}

// CKEditor encode links. This breaks emails using dynamic link through Liquid variables.
// We decode braces so backend can read liquid syntax.
function decodeLiquid(text) {
  return text
    .replaceAll(new RegExp("%7B%7B", "g"), "{{")
    .replace(new RegExp("%7D%7D", "g"), "}}")
    .replaceAll("&quot;", '"');
}

// ############# AUTOCOMPLETE FUNCTIONS ################### //
//  source: https://github.com/ckeditor/ckeditor4/blob/master/plugins/autocomplete/plugin.js
function textTestCallback(range) {
  if (!range.collapsed) {
    return null;
  }

  return CKEDITOR.plugins.textMatch.match(range, matchCallback);
}

function itemTemplate() {
  return '<li data-id="{id}">{label}</li>';
}

function matchCallback(text, offset) {
  const left = text.slice(0, offset);
  const match = left.match(/{{\w*$/);

  if (!match) {
    return null;
  }

  return { start: match.index, end: offset };
}

function dataCallback(autocompleteList, matchInfo, callback) {
  let suggestions = autocompleteList.filter(function (item) {
    let itemName = "{{" + item.matchString + "}}";
    return itemName.indexOf(matchInfo.query.toLowerCase()) == 0;
  });

  callback(suggestions);
}

function formatAutocompleteValues(autocompleteOptions) {
  return autocompleteOptions.map((value, index) => {
    const label = labelize(value);
    return {
      id: index,
      name: value,
      label: label,
      matchString: label.replaceAll(" ", "").toLowerCase(),
    };
  });
}

function labelize(str) {
  return str
    .replaceAll("{{", "")
    .replaceAll("}}", "")
    .replaceAll("[", "")
    .replaceAll("]", "")
    .replaceAll(".", " ")
    .replaceAll("_", " ")
    .replaceAll("Payable Owner", "Payer's")
    .split(" ")
    .map((str) => str[0].toUpperCase() + str.substring(1))
    .join(" ");
}

// ################################ //

function replace(elementId, value, autocompleteOptions, onChange) {
  const element = document.getElementById(elementId);
  let ck = CKEDITOR.replace(element, ckEditorNotificationsConfig());

  if (autocompleteOptions) {
    var autocompleteList = formatAutocompleteValues(autocompleteOptions);
    new CKEDITOR.plugins.autocomplete(ck, {
      textTestCallback: textTestCallback,
      dataCallback: function (matchInfo, callback) {
        dataCallback(autocompleteList, matchInfo, callback);
      },
      itemTemplate: itemTemplate(),
    });
  }

  ck.on("instanceReady", function () {
    this.setData(sanitizeText(value));
  });

  function updateModel() {
    onChange(decodeLiquid(ck.getData()));
  }

  ck.on("change", updateModel);
  ck.on("key", updateModel);
  ck.on("dataReady", updateModel);
}

const CKEditorPort = {
  add: function (app) {
    app.ports.ckEditorReplacePort.subscribe(function (args) {
      const elementId = args[0];
      const value = args[1];
      const autocompleteOptions = args[2];

      // Cross browser support
      const requestAnimationFrame =
        window.requestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.msRequestAnimationFrame;

      requestAnimationFrame(function () {
        replace(elementId, value, autocompleteOptions, function (value) {
          app.ports.ckEditorOnChangePort.send([elementId, value]);
        });
      });
    });

    app.ports.ckEditorSetDataPort.subscribe(function (args) {
      const elementId = args[0];
      const value = args[1];

      // Cross browser support
      const requestAnimationFrame =
        window.requestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.msRequestAnimationFrame;

      requestAnimationFrame(function () {
        const ck = CKEDITOR.instances[elementId];

        if (!ck) {
          return;
        }

        ck.setData(sanitizeText(value));
      });
    });
  },
};

module.exports = CKEditorPort;
