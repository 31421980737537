"use strict";

const StripePort = {
  add: function (app, hintStripePublicKey) {
    if (!window.Stripe) {
      return;
    }

    let stripeElements = {};

    // Cross browser support
    const requestAnimationFrame =
      window.requestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.msRequestAnimationFrame;

    const mountAttemptsMax = 20;
    let mountAttempts = 0;

    let elements;
    function buildAndMountElementWithSetupIntent(stripeClient, params) {
      const cardElement = document.querySelector(params.selector);

      elements = stripeClient.elements({
        clientSecret: params.setupIntentSecret,
        appearance: {
          theme: "flat",
          variables: {
            borderRadius: "3px",
            colorBackground: "#FFFFFF",
            colorPrimary: "#1890FF",
            colorText: "#1D2334",
            colorTextPlaceholder: "#497AA5",
            colorTextSecondary: "#497AA5",
            fontFamily: "Avenir",
            fontSizeBase: " 0.875rem",
            colorIconTabSelected: "#FFFFFF",
            spacingGridRow: "1rem",
            colorIconHover: "#006cd1",
            colorIconTabHover: "#006cd1",
          },
          rules: {
            ".Tab--selected": {
              backgroundColor: "#006cd1",
              border: "0px",
              color: "#FFFFFF",
            },
            ".Tab:hover, .PickerItem:hover": {
              color: "#006cd1",
            },
            ".DropdownItem--highlight": {
              backgroundColor: "#F1F6FC",
              border: "1px solid #81AFE4",
            },
            ".Tab, .Input, .PickerItem": {
              border: "1px solid #c4dcf8",
              boxShadow: "none",
            },
            ".Label": {
              color: "#497aa4",
              textTransform: "uppercase",
              lineHeight: "25px",
              marginBottom: "5px",
            },
            ".PickerItem": {
              color: "#497aa4",
              textTransform: "uppercase",
              lineHeight: "25px",
            },
            ".PickerItem:hover": {
              backgroundColor: "#FFFFFF",
            },
            ".Tab:focus, .Tab--selected:focus": {
              outline: "none",
              boxShadow: "0px",
            },
          },
        },
      });
      const disallowedBrands = params.acceptAmex ? [] : ["amex"];
      const paymentElement = elements.create("payment", {
        terms: {
          card: "never",
          usBankAccount: "never",
        },
        defaultValues: {
          billingDetails: {
            name: params.name,
            address: {
              postal_code: params.zip,
            },
          },
        },
        disallowedCardBrands: disallowedBrands,
        wallets: {
          applePay: "never",
          googlePay: "never",
        },
      });

      paymentElement.mount(cardElement);

      paymentElement.on("change", function (element) {
        const type = (element && element.value && element.value.type) || "";
        app.ports.stripePortOnSetupIntentChange.send(type);
      });

      return {
        stripeClient: stripeClient,
        elements: elements,
        paymentElement: paymentElement,
        options: params.options,
      };
    }

    function tryBuildAndMountElementWithSetupIntent(params) {
      const stripeKey = hintStripePublicKey;
      const stripeClient = Stripe(stripeKey, {
        betas: ["blocked_card_brands_beta_1"],
        apiVersion: "2019-12-03",
      });
      const cardElement = document.querySelector(params.selector);

      if (cardElement) {
        requestAnimationFrame(function () {
          stripeElements[params.selector] = buildAndMountElementWithSetupIntent(
            stripeClient,
            params,
          );
        });
      } else {
        if (mountAttempts <= mountAttemptsMax) {
          mountAttempts += 1;
          setTimeout(function () {
            tryBuildAndMountElementWithSetupIntent(params);
          }, 100);
        }
      }
    }

    app.ports.stripePortBuildAndMountElementWithSetupIntent.subscribe(
      tryBuildAndMountElementWithSetupIntent,
    );

    app.ports.stripePortConfirmSetupIntent.subscribe(function (params) {
      const stripeElement = stripeElements[params.selector];

      const billingDetails =
        params.options && params.options.billingOptions
          ? {
              billing_details: {
                name: params.options.billingOptions.name,
              },
            }
          : {};

      stripeElement.stripeClient
        .confirmSetup({
          elements,
          confirmParams: {
            return_url: window.location.href, // handle a return url here for 3d secure cards
            payment_method_data: billingDetails,
          },
          redirect: "if_required", // only redirect if needed for 3d secure
        })
        .then(({ error, setupIntent }) => {
          if (error) {
            app.ports.stripePortOnSetupIntentConfirmed.send({
              selector: params.selector,
              setup_intent: null,
              error: error.message,
            });
          } else {
            app.ports.stripePortOnSetupIntentConfirmed.send({
              selector: params.selector,
              setup_intent: setupIntent,
              error: null,
            });
          }
        });
    });
  },
};

module.exports = StripePort;
