"use strict";

const PageTitle = {
  add: (app) => {
    app.ports.updateTabTitle.subscribe((title) => {
      document.title = title;
    });
  },
};

module.exports = PageTitle;
