"use strict";

const StripeTerminalPort = {
  add: function (app) {
    if (!window.StripeTerminal) {
      return;
    }

    let stripeTerminals = {};

    app.ports.stripeTerminalPortCreate.subscribe(function (token) {
      stripeTerminals[token] = StripeTerminal.create({
        onFetchConnectionToken: function () {
          return token;
        },
        onUnexpectedReaderDisconnect: function () {
          app.ports.stripeTerminalPortOnUnexpectedReaderDisconnect.send(
            "Disconnected from reader",
          );
        },
      });

      app.ports.stripeTerminalPortOnCreated.send(token);
    });

    app.ports.stripeTerminalPortConnectReader.subscribe(function (args) {
      const token = args[0];
      const stripeObject = args[1];

      let discoverReaders;
      if (stripeObject.device_type == "simulated_wisepos_e") {
        discoverReaders = new Promise(function (resolve, reject) {
          stripeTerminals[token]
            .discoverReaders({ simulated: true })
            .then(function (discoverResult) {
              if (discoverResult.error) {
                reject(discoverResult.error);
              } else if (discoverResult.discoveredReaders.length === 0) {
                reject("No available readers.");
              } else {
                resolve(discoverResult.discoveredReaders[0]);
              }
            });
        });
      } else {
        discoverReaders = new Promise(function (resolve) {
          resolve(stripeObject);
        });
      }

      discoverReaders.then(function (selectedReader) {
        stripeTerminals[token]
          .connectReader(selectedReader)
          .then(function (result) {
            const error = result.error || {};
            app.ports.stripeTerminalPortOnReaderConnected.send([
              token,
              error.message || null,
            ]);
          });
      });
    });

    app.ports.stripeTerminalPortReadReusableCard.subscribe(function (token) {
      stripeTerminals[token].readReusableCard().then(function (result) {
        if (result.error) {
          app.ports.stripeTerminalPortOnReusableCardRead.send([
            token,
            null,
            result.error,
          ]);
        } else {
          app.ports.stripeTerminalPortOnReusableCardRead.send([
            token,
            result.payment_method.id,
            null,
          ]);
        }
      });
    });

    app.ports.stripeTerminalPortCancelReadReusableCard.subscribe(
      function (token) {
        stripeTerminals[token].cancelReadReusableCard().then(function (result) {
          app.ports.stripeTerminalPortOnReadReusableCardCancelled.send([
            token,
            result.error || null,
          ]);
        });
      },
    );

    app.ports.stripeTerminalPortDisconnectReader.subscribe(function (token) {
      stripeTerminals[token].disconnectReader();
    });

    app.ports.stripeTerminalPortClearCachedCredentials.subscribe(
      function (token) {
        stripeTerminals[token].clearCachedCredentials();
      },
    );
  },
};

module.exports = StripeTerminalPort;
