"use strict";

const GlobalSearchPort = {
  add: (app) => {
    document.addEventListener("keydown", (e) => {
      if ((e.metaKey || e.ctrlKey) && e.key === "k") {
        app.ports.globalSearchTriggeredPort.send(null);
        e.preventDefault();
      }
    });

    window.addEventListener("outsideGlobalSearchTriggered", function () {
      app.ports.globalSearchTriggeredPort.send(null);
    });
  },
};

module.exports = GlobalSearchPort;
