"use strict";

import * as Sentry from "@sentry/browser";

function captureMessage(message, level) {
  if (!["feature", "staging", "production"].includes(CONFIGURATION.nodeEnv)) {
    if (level === "info") {
      console.error(message);
    } else if (level === "debug") {
      console.log(message);
    }
  }

  Sentry.captureMessage(message, level);
}

const SentryPort = {
  add: function (app) {
    app.ports.captureErrorMessage.subscribe(function (message) {
      captureMessage(message, "info");
    });

    app.ports.captureDebugMessage.subscribe(function (message) {
      captureMessage(message, "debug");
    });
  },
  captureMessage: captureMessage,
  measure: Sentry.metrics.set,
};

module.exports = SentryPort;
