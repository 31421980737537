"use strict";

const zxcvbn = require("zxcvbn");

const PasswordStrengthPort = {
  add: function (app) {
    app.ports.checkPasswordStrength.subscribe((password) => {
      const { score, feedback } = zxcvbn(password);

      app.ports.passwordStrengthChecked.send({
        strength: score,
        suggestions: feedback?.suggestions,
      });
    });
  },
};

module.exports = PasswordStrengthPort;
