class FronteggService {
  constructor(options) {
    this.app = options.app;
  }

  subscribeToAuth(onInit, onAuthenticated, onUnAuthenticated) {
    this.app.ready(() => {
      onInit();
      this.app.store.subscribe(() => {
        const state = this.app.store.getState();
        if (!state.auth.isLoading) {

          if (!state.auth.isAuthenticated) {
            onUnAuthenticated();
          } else {
            onAuthenticated(state.auth);
          }
        }
      })
    });
  }

  static buildLogoutUrl(loginUrl, postLogoutRedirectURI){
    // this.app.logout(); // TODO: bug in Frontegg that doesn't allow to use it in Firefox, so instead we should use the next

    let url = new URL(`${loginUrl}/oauth/logout`);
    let options = {
      post_logout_redirect_uri: `${window.location.origin}${postLogoutRedirectURI}`,
    };
    url.search = new URLSearchParams(options);
    return url;
  }
}


export { FronteggService }
