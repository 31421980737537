import Cookies from 'js-cookie';

class CookieManager {
  constructor(options) {
    const isDevelopment = options.isDevelopment || false;
    const isSafari = window.safari !== undefined;
    const appPrefix = 'hint.';
    const daysToExpire = 1;

    this.customPolicy = { domain: options.domain, expires: daysToExpire };
    if (isDevelopment) {
      this.customPolicy = Object.assign(this.customPolicy, { sameSite: 'none', secure: true });
    }

    if (isSafari && isDevelopment) {
      delete this.customPolicy['secure'];
    }

    this.prefix = appPrefix + options.domain.replace(new RegExp("^\\."), "").replace('.com', '') + '.';
  }

  store(key, value) {
    return Cookies.set(this.prefix + key, value, this.customPolicy);
  }

  retrieve(key) {
    return Cookies.get(this.prefix + key);
  }

  remove(key) {
    return Cookies.remove(this.prefix + key, this.customPolicy);
  }
}


export { CookieManager }
