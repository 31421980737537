"use strict";

import { Decoder } from "@nuintun/qrcode";
import * as Sentry from "@sentry/browser";

// workaround until we move away from our MFA, this will decode the QR and allows to send it back to the api so we can
// store it in case we want to rollback to the old MFA system
const qrDecoder = {
  add: function (app) {
    app.ports.decodePort.subscribe((base64) => {
      const qrcode = new Decoder();
      qrcode
        .scan(base64)
        .then(({ data }) => {
          app.ports.onDecodePort.send(data);
        })
        .catch((error) => {
          Sentry.captureMessage(error);
        });
    });
  },
};

module.exports = qrDecoder;
