"use strict";

import { FullStory } from "@fullstory/browser";

module.exports = {
  log: function (fronteggApp) {
    // if the user enters to an already used callback, frontegg fails
    var _error = console.error;
    console.error = function () {
      if (
        typeof arguments[0] === "string" &&
        arguments[0].includes("exchangeOAuthTokens")
      ) {
        setTimeout(() => {
          if (!fronteggApp.store.getState().auth.isAuthenticated) {
            FullStory("trackEvent", { name: "Failed to login" });
          }
        }, 5000);
      }
      return _error.apply(console, arguments);
    };
  }
}
