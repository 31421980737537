"use strict";

const LocalStoragePort = require("./local-storage.js");
const customOrgKey = "hint.custom-organization";
const queryParam = "organization";

function appendQueryParam(url, paramName, paramValue) {
  if (url.indexOf("?") === -1) {
    return url + "?" + paramName + "=" + paramValue;
  } else {
    return url + "&" + paramName + "=" + paramValue;
  }
}

module.exports = {
  get: function (url) {
    const org = LocalStoragePort.getItem(customOrgKey);
    if (org !== null) {
      return appendQueryParam(url, queryParam, org);
    }
    return url;
  },
  setAndRetrieve: function () {
    const urlQueryParams = new URLSearchParams(window.location.search);
    let organization = urlQueryParams.get(queryParam);
    if (organization) {
      LocalStoragePort.setItem(customOrgKey, organization);
    } else {
      organization = LocalStoragePort.getItem(customOrgKey);
    }
    return organization;
  },
};
