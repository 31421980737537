"use strict";

const LocalStoragePort = require("./../local-storage.js");
const returnUrlKey = "hint.redirect-after-login";

function fromCallback() {
  return window.location.href.includes("oauth/callback");
}

module.exports = {
  persistUrl: function (forcedUrl) {
    const defaultUrl = window.location.href;
    if (
      forcedUrl !== undefined ||
      (!fromCallback() &&
        !["login", "logout", "callback", "join"].some((s) =>
          defaultUrl.includes(s),
        ))
    ) {
      let url = forcedUrl || defaultUrl;
      LocalStoragePort.setItem(returnUrlKey, url);
    }
  },
  retrieveUrl: function () {
    return LocalStoragePort.getItem(returnUrlKey);
  },
  resetUrl: function () {
    LocalStoragePort.removeItem(returnUrlKey);
  },
};
