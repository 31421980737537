"use strict";

const NavbarPort = {
  add: (app) => {
    app.ports.sidenavLinkClickedPort.subscribe(() => {
      app.ports.onSidenavLinkClickedPort.send(null);
    });

    window.addEventListener("outsideHinterViewUpdated", function (customEvent) {
      app.ports.sidenavHinterViewUpdatedPort.send(
        customEvent.detail["hinterViewOff"],
      );
    });

    app.ports.topnavHeaderHeightChangedPort.subscribe((headerHeight) => {
      const root = document.documentElement;

      root.style.setProperty("--total-header-height", headerHeight + "px");

      // On mobile, the sidebar slides in from the top, covering the topnav.
      // Those 55px need to be accounted for when adjusting the wrapper's margin.
      const mobileNavHeight = window.innerWidth <= 768 ? getTopnavHeight() : 0;
      root.style.setProperty("--mobile-nav-height", mobileNavHeight + "px");
    });

    if (app.ports.globalSearchButtonClickedPort) {
      app.ports.globalSearchButtonClickedPort.subscribe(() => {
        const event = new Event("outsideGlobalSearchTriggered");
        window.dispatchEvent(event);
      });
    }

    const getTopnavHeight = () => {
      const style = getComputedStyle(document.documentElement).getPropertyValue(
        "--topnav-height",
      );
      return parseInt(style.replace("px", "").trim());
    };
  },
};

module.exports = NavbarPort;
