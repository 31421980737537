module Main exposing (Model, Msg, main)

import Api.Data as Data
import Browser
import Browser.Dom as Dom
import Platform.Cmd as Cmd
import Ports.Amplitude as Amplitude
import Ports.Amplitude.State
import Ports.Navigation exposing (load, onUrlChange, onUrlRequest, pushUrl)
import Ports.PageTitle exposing (updateTabTitle)
import Ports.Sentry as Sentry
import Shared exposing (Flags)
import Spa.Document as Document exposing (Document)
import Spa.Generated.Pages as Pages
import Spa.Generated.Route as Route exposing (Route)
import Spa.Layout as Layout
import Url exposing (Url)
import Utils.Route
import Utils.Scroll as Scroll


main : Program Flags Model Msg
main =
    Browser.element
        { init = init
        , update = update
        , subscriptions = subscriptions
        , view = view >> Document.toBrowserElement
        }



-- INIT


type alias Model =
    { shared : Shared.Model
    , route : Route
    , page : Pages.Model
    , layout : Layout.Model
    }


init : Flags -> ( Model, Cmd Msg )
init flags =
    let
        ( url, defaultUrlCmd ) =
            locationHrefToUrl flags.locationHref

        route =
            Utils.Route.fromUrl url

        ( shared, sharedCmd ) =
            Shared.init flags url

        ( page, pageCmd, outMsgs ) =
            Pages.init route shared

        ( newShared, extraSharedCmd ) =
            Shared.updateFromOutMsgs outMsgs shared

        ( layout, layoutCmd ) =
            Layout.init shared
    in
    ( Model newShared route page layout
    , Cmd.batch
        [ Cmd.map Shared sharedCmd
        , Cmd.map Shared extraSharedCmd
        , Cmd.map Pages pageCmd
        , Cmd.map Layout layoutCmd
        , defaultUrlCmd
        ]
    )



-- UPDATE


type Msg
    = UrlChanged String
    | UrlRequested String Bool Bool
    | Shared Shared.Msg
    | Pages Pages.Msg
    | Layout Layout.Msg
    | AttemptedScrollingToFragment Int String (Result Dom.Error ())


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case msg of
        AttemptedScrollingToFragment attempts fragment result ->
            case result of
                Err _ ->
                    ( model
                    , attemptScrollingToFragment (attempts + 1) fragment
                    )

                Ok _ ->
                    ( model, Cmd.none )

        UrlRequested url isLocal shouldOpenInNewWindow ->
            let
                original =
                    model.shared

                hasPendingChanges =
                    original.pendingChangesDetail /= Nothing
            in
            ( if shouldOpenInNewWindow then
                model

              else if hasPendingChanges && original.pendingChangesUrlRequested == Nothing then
                { model | shared = { original | pendingChangesUrlRequested = Just ( url, isLocal ) } }

              else
                model
            , if hasPendingChanges then
                Cmd.none

              else if isLocal then
                pushUrl url

              else
                Cmd.batch
                    [ Amplitude.logExternalNavigation (Data.toMaybe model.shared.session) Nothing (Ports.Amplitude.State.fromRoute model.route) url
                    , if shouldOpenInNewWindow then
                        Cmd.none

                      else
                        load url
                    ]
            )

        UrlChanged locationHref ->
            if model.shared.embedded && model.route /= Route.Blank then
                ( model, Cmd.none )

            else
                let
                    ( url, defaultUrlCmd ) =
                        locationHrefToUrl locationHref

                    route =
                        Utils.Route.fromUrl url

                    original =
                        model.shared

                    ( shared, urlChangedCmd ) =
                        Shared.urlChanged url original

                    initPage =
                        original.url.path /= shared.url.path || model.route == Route.Blank

                    ( page, pageCmd, outMsgs ) =
                        if initPage then
                            Pages.init route shared

                        else
                            ( model.page, Cmd.none, [] )

                    ( newShared, sharedCmd ) =
                        Pages.save page shared
                            |> Shared.updateFromOutMsgs outMsgs

                    ( layout, layoutCmd ) =
                        Layout.urlChanged shared model.layout

                    { title } =
                        Pages.view page
                in
                ( { model
                    | page = page
                    , route = route
                    , layout = layout
                    , shared = Pages.save page newShared |> Shared.rotateFooterIf (initPage && route /= Route.Login)
                  }
                , Cmd.batch
                    [ Cmd.map Pages pageCmd
                    , Cmd.map Shared sharedCmd
                    , Cmd.map Layout layoutCmd
                    , defaultUrlCmd
                    , url.fragment |> Maybe.map (attemptScrollingToFragment 0) |> Maybe.withDefault Cmd.none
                    , Cmd.map Shared urlChangedCmd
                    , updateTabTitle title
                    ]
                )

        Shared sharedMsg ->
            let
                ( shared, sharedCmd ) =
                    Shared.update sharedMsg model.shared

                ( page, pageCmd, outMsgs ) =
                    Pages.load model.page shared

                ( newShared, newSharedCmd ) =
                    Shared.updateFromOutMsgs outMsgs shared

                ( layout, layoutCmd ) =
                    Layout.load shared model.layout
            in
            ( { model | page = page, shared = newShared, layout = layout }
            , Cmd.batch
                [ Cmd.map Shared sharedCmd
                , Cmd.map Shared newSharedCmd
                , Cmd.map Pages pageCmd
                , Cmd.map Layout layoutCmd
                ]
            )

        Pages pageMsg ->
            let
                ( page, pageCmd, outMsgs ) =
                    Pages.update pageMsg model.page

                ( shared, sharedCmd ) =
                    Pages.save page model.shared
                        |> Shared.updateFromOutMsgs outMsgs

                { title } =
                    Pages.view page
            in
            ( { model | page = page, shared = shared }
            , Cmd.batch
                [ Cmd.map Pages pageCmd
                , Cmd.map Shared sharedCmd
                , updateTabTitle title
                ]
            )

        Layout layoutMsg ->
            let
                ( layout, layoutCmd, outMsgs ) =
                    Layout.update model.shared.apiClient layoutMsg model.layout

                ( shared, sharedCmd ) =
                    Shared.updateFromOutMsgs outMsgs model.shared
            in
            ( { model | layout = layout, shared = shared }
            , Cmd.batch
                [ Cmd.map Layout layoutCmd
                , Cmd.map Shared sharedCmd
                ]
            )


view : Model -> Document Msg
view model =
    Shared.view
        { page =
            Pages.view model.page
                |> Document.map Pages
        , toMsg = Shared
        , layout = Layout.view Layout model.layout model.shared
        }
        model.shared


subscriptions : Model -> Sub Msg
subscriptions model =
    Sub.batch
        [ onUrlChange UrlChanged
        , onUrlRequest UrlRequested
        , Shared.subscriptions model.shared
            |> Sub.map Shared
        , Pages.subscriptions model.page
            |> Sub.map Pages
        , Layout.subscriptions model.layout
            |> Sub.map Layout
        ]



-- URL


locationHrefToUrl : String -> ( Url, Cmd msg )
locationHrefToUrl locationHref =
    case Url.fromString locationHref of
        Just url ->
            ( url, Cmd.none )

        Nothing ->
            ( defaultUrl, Sentry.captureErrorMessage <| "Url.fromString Failed: " ++ locationHref )


defaultUrl : Url
defaultUrl =
    Url Url.Http "localhost" Nothing "" Nothing Nothing


attemptScrollingToFragment : Int -> String -> Cmd Msg
attemptScrollingToFragment attempts fragment =
    if attempts > 3 then
        Cmd.none

    else
        AttemptedScrollingToFragment (attempts + 1) fragment
            |> Scroll.toElementByIdWithDelay 500 fragment
