const _temp0 = require("./assets/images/arrow-down-red.png");

const _temp1 = require("./assets/images/arrow-grey.svg");

const _temp2 = require("./assets/images/arrow-up-green.png");

const _temp3 = require("./assets/images/elationemr-blue-logo.png");

const _temp4 = require("./assets/images/elationemr-logo.png");

const _temp5 = require("./assets/images/find-ga-code.png");

const _temp6 = require("./assets/images/help-circle.png");

const _temp7 = require("./assets/images/mailgun-logo.png");

const _temp8 = require("./assets/images/sample-check.png");

const _temp9 = require("./assets/images/stripe-logo.png");

const _temp10 = require("./assets/images/stripe-terminal-verifone-default-splash.png");

module.exports = {
  "arrow-down-red":   {
    "png": _temp0
  },
  "arrow-grey":   {
    "svg": _temp1
  },
  "arrow-up-green":   {
    "png": _temp2
  },
  "elationemr-blue-logo":   {
    "png": _temp3
  },
  "elationemr-logo":   {
    "png": _temp4
  },
  "find-ga-code":   {
    "png": _temp5
  },
  "help-circle":   {
    "png": _temp6
  },
  "mailgun-logo":   {
    "png": _temp7
  },
  "sample-check":   {
    "png": _temp8
  },
  "stripe-logo":   {
    "png": _temp9
  },
  "stripe-terminal-verifone-default-splash":   {
    "png": _temp10
  }
}