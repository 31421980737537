"use strict";
const setup = function (googleRecaptchaSiteKey) {
  function loadRecaptchaAPI() {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://www.google.com/recaptcha/api.js?render=" +
      googleRecaptchaSiteKey;
    document.body.appendChild(script);
  }

  if (googleRecaptchaSiteKey) {
    window.addEventListener("load", loadRecaptchaAPI, false);
  }
};

const GoogleRecaptchaPort = {
  add: function (app, googleRecaptchaSiteKey) {
    setup(googleRecaptchaSiteKey);

    app.ports.getRecaptchaTokenPort.subscribe(function (action) {
      if (typeof grecaptcha !== "undefined") {
        grecaptcha.ready(function () {
          grecaptcha
            .execute(googleRecaptchaSiteKey, { action: action })
            .then(function (token) {
              app.ports.gotRecaptchaTokenPort.send({
                token: token,
                result: "ok",
              });
            })
            .catch(() =>
              app.ports.gotRecaptchaTokenPort.send({
                token: "",
                result: "remote-error",
              }),
            );
        });
      } else {
        app.ports.gotRecaptchaTokenPort.send({
          token: "",
          result: "recaptcha-not-defined",
        });
      }
    });
  },
};

module.exports = GoogleRecaptchaPort;
