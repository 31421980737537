/**
 * @module QRCode
 * @package @nuintun/qrcode
 * @license MIT
 * @version 3.3.5
 * @author nuintun <nuintun@qq.com>
 * @description A pure JavaScript QRCode encode and decode library.
 * @see https://github.com/nuintun/qrcode#readme
 */

import { __assign } from 'tslib';
import { BitStream } from './BitStream.js';
import { Mode } from '../../../common/Mode.js';
import { decode as decode$1 } from '../../../../encoding/UTF8.js';
import { decode, getTables } from '../../../../encoding/SJIS.js';

/**
 * @module index
 * @author nuintun
 * @author Cosmo Wolfe
 * @license https://raw.githubusercontent.com/cozmo/jsQR/master/LICENSE
 */
function decodeNumeric(stream, size) {
  var data = '';
  var bytes = [];
  var characterCountSize = [10, 12, 14][size];
  var length = stream.readBits(characterCountSize);
  // Read digits in groups of 3
  while (length >= 3) {
    var num = stream.readBits(10);
    if (num >= 1000) {
      throw new Error('invalid numeric value above 999');
    }
    var a = Math.floor(num / 100);
    var b = Math.floor(num / 10) % 10;
    var c = num % 10;
    bytes.push(48 + a, 48 + b, 48 + c);
    data += a.toString() + b.toString() + c.toString();
    length -= 3;
  }
  // If the number of digits aren't a multiple of 3, the remaining digits are special cased.
  if (length === 2) {
    var num = stream.readBits(7);
    if (num >= 100) {
      throw new Error('invalid numeric value above 99');
    }
    var a = Math.floor(num / 10);
    var b = num % 10;
    bytes.push(48 + a, 48 + b);
    data += a.toString() + b.toString();
  } else if (length === 1) {
    var num = stream.readBits(4);
    if (num >= 10) {
      throw new Error('invalid numeric value above 9');
    }
    bytes.push(48 + num);
    data += num.toString();
  }
  return { bytes: bytes, data: data };
}
// prettier-ignore
var AlphanumericCharacterCodes = [
    '0', '1', '2', '3', '4', '5', '6', '7', '8',
    '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H',
    'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q',
    'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
    ' ', '$', '%', '*', '+', '-', '.', '/', ':'
];
function decodeAlphanumeric(stream, size) {
  var bytes = [];
  var characterCountSize = [9, 11, 13][size];
  var data = '';
  var length = stream.readBits(characterCountSize);
  while (length >= 2) {
    var v = stream.readBits(11);
    var a = Math.floor(v / 45);
    var b = v % 45;
    bytes.push(AlphanumericCharacterCodes[a].charCodeAt(0), AlphanumericCharacterCodes[b].charCodeAt(0));
    data += AlphanumericCharacterCodes[a] + AlphanumericCharacterCodes[b];
    length -= 2;
  }
  if (length === 1) {
    var a = stream.readBits(6);
    bytes.push(AlphanumericCharacterCodes[a].charCodeAt(0));
    data += AlphanumericCharacterCodes[a];
  }
  return { bytes: bytes, data: data };
}
function decodeByte(stream, size, encoding) {
  var bytes = [];
  var characterCountSize = [8, 16, 16][size];
  var length = stream.readBits(characterCountSize);
  for (var i = 0; i < length; i++) {
    bytes.push(stream.readBits(8));
  }
  return { bytes: bytes, data: encoding === 20 /* EncodingHint.SJIS */ ? decode(bytes) : decode$1(bytes) };
}
function decodeKanji(stream, size) {
  var data = '';
  var bytes = [];
  var fromCharCode = String.fromCharCode;
  var SJIS_TO_UTF8 = getTables().SJIS_TO_UTF8;
  var characterCountSize = [8, 10, 12][size];
  var length = stream.readBits(characterCountSize);
  for (var i = 0; i < length; i++) {
    var k = stream.readBits(13);
    var c = (Math.floor(k / 0xc0) << 8) | k % 0xc0;
    if (c < 0x1f00) {
      c += 0x8140;
    } else {
      c += 0xc140;
    }
    bytes.push(c >> 8, c & 0xff);
    var b = SJIS_TO_UTF8[c];
    data += fromCharCode(b != null ? b : c);
  }
  return { bytes: bytes, data: data };
}
function bytesDecode(data, version, errorCorrectionLevel) {
  var _a, _b, _c, _d;
  var encoding = -1;
  var stream = new BitStream(data);
  // There are 3 'sizes' based on the version. 1-9 is small (0), 10-26 is medium (1) and 27-40 is large (2).
  var size = version <= 9 ? 0 : version <= 26 ? 1 : 2;
  var result = { data: '', bytes: [], chunks: [], version: version, errorCorrectionLevel: errorCorrectionLevel };
  while (stream.available() >= 4) {
    var mode = stream.readBits(4);
    if (mode === Mode.Terminator) {
      return result;
    } else if (mode === Mode.ECI) {
      if (stream.readBits(1) === 0) {
        encoding = stream.readBits(7);
      } else if (stream.readBits(1) === 0) {
        encoding = stream.readBits(14);
      } else if (stream.readBits(1) === 0) {
        encoding = stream.readBits(21);
      } else {
        // ECI data seems corrupted
        encoding = -1;
      }
    } else if (mode === Mode.Numeric) {
      var numericResult = decodeNumeric(stream, size);
      result.data += numericResult.data;
      result.chunks.push({
        mode: Mode.Numeric,
        data: numericResult.data,
        bytes: numericResult.bytes
      });
      (_a = result.bytes).push.apply(_a, numericResult.bytes);
    } else if (mode === Mode.Alphanumeric) {
      var alphanumericResult = decodeAlphanumeric(stream, size);
      result.data += alphanumericResult.data;
      result.chunks.push({
        mode: Mode.Alphanumeric,
        data: alphanumericResult.data,
        bytes: alphanumericResult.bytes
      });
      (_b = result.bytes).push.apply(_b, alphanumericResult.bytes);
    } else if (mode === Mode.StructuredAppend) {
      // QR Standard section 9.2
      var structuredAppend = {
        // [current, total]
        symbols: [stream.readBits(4), stream.readBits(4)],
        parity: stream.readBits(8)
      };
      result.chunks.push(__assign({ mode: Mode.StructuredAppend }, structuredAppend));
    } else if (mode === Mode.Byte) {
      var byteResult = decodeByte(stream, size, encoding);
      result.data += byteResult.data;
      result.chunks.push({
        encoding: encoding,
        mode: Mode.Byte,
        data: byteResult.data,
        bytes: byteResult.bytes
      });
      (_c = result.bytes).push.apply(_c, byteResult.bytes);
    } else if (mode === Mode.Kanji) {
      var kanjiResult = decodeKanji(stream, size);
      result.data += kanjiResult.data;
      result.chunks.push({
        mode: Mode.Kanji,
        data: kanjiResult.data,
        bytes: kanjiResult.bytes
      });
      (_d = result.bytes).push.apply(_d, kanjiResult.bytes);
    }
  }
  // If there is no data left, or the remaining bits are all 0, then that counts as a termination marker
  if (stream.available() === 0 || stream.readBits(stream.available()) === 0) {
    return result;
  }
  return null;
}

export { bytesDecode };
