import { CookieManager } from "./cookie-manager"
import { FronteggService } from "./frontegg-service"
import { ApiService } from "./api-service"

class SessionManager {
  #defaultTimeout = 15;
  #warningMinsBeforeLogout = 2;
  constructor(options) {
    this.postLogoutRedirectURI = options.postLogoutRedirectURI;
    this.onLogout = options.onLogout || function () {};
    this.onLogout = options.onLogout || function () {};
    this.logoutTimeout = null;
    this.logoutWarningTimeout = null;
    this.app = null;
    this.token = null;
    this.sessionTimeout = this.#defaultTimeout;
    this.domain = options.domain || this.resolveSharedDomain();
    this.cookieManager = new CookieManager({
      isDevelopment: options.isDevelopment,
      domain: this.domain,
    });
    this.logger = options.logger || false;
    this.loginUrl = options.loginUrl;
    this.backupAuth = options.backupAuth || false;
    this.apiService = new ApiService(options.apiUrl);
  }

  run(options) {
    if (typeof options === "undefined") {
      options = {};
    }

    this.app = options.app;

    if(!this.app) {
      console.error('App not provided');
      return;
    }

    this.onAccessTokenChange = options.onAccessTokenChange || function () { };
    this.onExpirationWarning = options.onExpirationWarning || function () { };
    this.onRefreshExpiration = options.onRefreshExpiration || function () {};
    const onUnAuthenticated = options.onUnAuthenticated || function () {};

    const onInit = this.onInit.bind(this);
    const onAuthenticated = this.onAuthenticated.bind(this);
    const subscribeToAuth = function () {
      const fronteggService = new FronteggService({ app: this.app });
      fronteggService.subscribeToAuth(
        onInit,
        onAuthenticated,
        onUnAuthenticated,
      );
    }.bind(this);

    subscribeToAuth();

    if (this.backupAuth) {
      this.setupSessionWatchers();
    }
  }

  onInit() {
    if (this.cookieManager.retrieve("session_logout")) {
      this.cookieManager.remove("session_logout");
    }
  }

  onAuthenticated(authData) {
    this.sharedTabsLogoutChecker();

    if (this.token != authData.user.accessToken) {
      this.token = authData.user.accessToken;
      if (this.logger) {
        console.log("There is a new token");
      }
      this.apiService.createSession(this.token, (response) => {
        if (!response.ok) {
          setTimeout(function () {
            // retrying to create session
            this.token = null;
            this.onAuthenticated(authData)
          }, 5000);
        } else {
          this.onSessionCreated(response, this.token)
        }
      });
      this.setSessionTimeout(this.app.store.getState().auth.tenantsState.activeTenant);
      this.checkForActiveSession();
    }
  }

  onSessionCreated(response){
    this.onAccessTokenChange(this.token);
    if(response.status !== '201') {
      // session already exists, so we need to check if it's expired
      this.checkSessionExpired();
    }
  }

  setupSessionWatchers() {
    // we will check on session expiration if the user had activity and warn it 2mins before
    this.scheduleLogout(this.expiresIn());
    this.scheduleLogoutWarning(this.warnExpirationIn());
    this.updateExpiresAt();
  }

  logout() {
    this.onLogout();
    this.apiService.deleteSession(this.token);
    this.token = null;

    if (!this.cookieManager.retrieve("session_logout")) {
      this.cookieManager.store("session_logout", true);
    }
    this.cookieManager.remove("session_expires_at");

    let url;

    if (this.backupAuth) {
      url = new URL(this.loginUrl);
    } else {
      url = FronteggService.buildLogoutUrl(this.loginUrl, this.postLogoutRedirectURI)
    }
    window.location.href = url.toString();
  }

  scheduleLogout(expiresInSeconds) {
    clearTimeout(this.logoutTimeout);
    let checkSessionExpired = this.checkSessionExpired.bind(this);
    this.logoutTimeout = setTimeout(function () {
      checkSessionExpired();
    }, expiresInSeconds * 1000);
  }

  checkForActiveSession() {
    let active = this.setupSessionWatchers;
    const refreshFrequency = 60 * 1000;
    document.addEventListener(
      "mousemove",
      this.throttle(active.bind(this), refreshFrequency),
    );
  }

  scheduleLogoutWarning(expiresInSeconds) {
    clearTimeout(this.logoutWarningTimeout);
    let cookieManager = this.cookieManager;
    let onExpirationWarning = this.onExpirationWarning;
    let warningMinsBeforeLogout = this.#warningMinsBeforeLogout;
    this.logoutWarningTimeout = setTimeout(function () {
      const expiresAt = cookieManager.retrieve("session_expires_at");
      if (
        expiresAt &&
        new Date(Date.parse(expiresAt) - 1000 * 60 * warningMinsBeforeLogout) <
          new Date()
      ) {
        onExpirationWarning();
      }
    }, expiresInSeconds * 1000);
  }

  calculateExpiresAt(sessionTimeout) {
    return new Date(new Date().getTime() + sessionTimeout * 60 * 1000);
  }

  setSessionTimeout(organization) {
    try {
      const originalTimeout = this.sessionTimeout;
      if (!organization) {
        if (this.logger) {
          console.error("Could not find organization");
        }
        return;
      }
      this.sessionTimeout = JSON.parse(
        organization.metadata,
      ).sessionTimeoutInMinutes;
      if (this.sessionTimeout !== originalTimeout) {
        this.updateExpiresAt();
        if (this.logger) {
          console.log("Session timeout is", this.sessionTimeout);
        }
      }
    } catch (e) {
      if (this.logger) {
        console.error('Could not set session timeout ', e);
      }
    }
  }

  checkSessionExpired() {
    const expiresAt = this.cookieManager.retrieve("session_expires_at");
    if (expiresAt && new Date(Date.parse(expiresAt)) < new Date()) {
      if (this.logger) {
        console.log("Session expired, logging out");
      }
      this.logout();
    }
  }

  throttle(func, timeFrame) {
    var lastTime = 0;
    return function () {
      var now = Date.now();
      if (now - lastTime >= timeFrame) {
        func();
        lastTime = now;
      }
    };
  }

  expiresIn() {
    return this.sessionTimeout * 60;
  }

  warnExpirationIn() {
    return (this.sessionTimeout - this.#warningMinsBeforeLogout) * 60;
  }

  updateExpiresAt() {
    this.cookieManager.store(
      "session_expires_at",
      this.calculateExpiresAt(this.sessionTimeout),
    );
    this.onRefreshExpiration();
  }

  resolveSharedDomain() {
    const url = window.location.hostname;
    const rootDomainRegex = /(\.[^.]+){2,}$/;

    return url.match(rootDomainRegex) ? url.match(rootDomainRegex)[0] : url; // should output ".yourdomain.com"
  }

  sharedTabsLogoutChecker(){
    setInterval(() => {
      if (this.token && this.cookieManager.retrieve("session_logout")) {
        if (this.logger) {
          console.log("Another app logged out");
        }
        this.logout();
      }
    }, 5000);
  }
}

export default SessionManager;
