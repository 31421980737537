'use strict';

import { valueOrDefault } from 'chart.js/helpers';

module.exports = {
    loadChartjsPluginEmptyOverlay: function(Chart) {
        Chart.defaults.plugins.emptyOverlay = {
            message: 'No data available',
        };

        /**
         * Checks if the chart is currently empty
         * @param chart
         * @returns {boolean}
         */
        function isChartEmpty(chart) {
            if (chart.config.type == "doughnut") {
                var values = chart.data.datasets[0].data;
                if (values.length == 0) {
                    return true;
                }

                for (i = 0; i < values.length; i++) {
                    if (values[i] != 0) {
                        return false;
                    }
                }

                return true;
            }

            return chart.data.datasets[0] == null || chart.data.datasets[0].data.length === 0;
        }

        Chart.register({
            id: 'empty-overlay',
            afterDraw: (chart, _args, _opts) => {
                if (!isChartEmpty(chart)) { return; }
                const globalDefault = Chart.defaults.plugins.emptyOverlay;
                const emptyOpts = chart.options.plugins.emptyOverlay;

                const message = valueOrDefault(emptyOpts.message, globalDefault.message);

                var ctx = chart.ctx;
                ctx.save();
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.font = "22px Arial";
                ctx.fillStyle = "gray";
                ctx.fillText(message, chart.width / 2, chart.height / 2);
                ctx.fillStyle = "rgba(0, 0, 0, 0.05)";
                ctx.fillRect(0, 0, chart.width, chart.height);
                ctx.restore();
            }
        });
    }
};
