"use strict";

const LocalStoragePort = require("./local-storage.js");

const read = function () {
  return LocalStoragePort.getItem("hint.hinter-view-off") === "true";
};

const write = function (hinterViewOff) {
  return LocalStoragePort.setItem("hint.hinter-view-off", hinterViewOff);
};

const HinterViewPort = {
  read: read,
  add: function (app) {
    if (app.ports.hinterViewStorePort) {
      app.ports.hinterViewStorePort.subscribe((hinterViewOff) => {
        write(hinterViewOff);

        // The navbar listents for this event to update its internal value.
        // Remove this when the navbar is integrated with the main app.
        const event = new CustomEvent("outsideHinterViewUpdated", {
          detail: { hinterViewOff: hinterViewOff },
        });
        window.dispatchEvent(event);
      });
    }
  },
};

module.exports = HinterViewPort;
