"use strict";

const calculateUnderlinePosition = (navId, activeTab) => {
  const nav = document.querySelector(`#${navId}`),
    activeItemRect = activeTab.getBoundingClientRect(),
    navRect = nav.getBoundingClientRect();

  return {
    width: activeItemRect.width,
    left: activeItemRect.x - navRect.x,
  };
};

const findActiveTab = (navId) => {
  return document.querySelector(`#${navId} > .nav-tabs__item--active`);
};

const sendUnderlinePositionThroughPort = (navId, portFun, retryCount) => {
  // First render is extremely slow, thus the timeout and the many retries.
  const activeTab = findActiveTab(navId);

  if (activeTab) {
    portFun.send(calculateUnderlinePosition(navId, activeTab));
  } else if (retryCount > 20) {
    return false;
  } else {
    setTimeout(() => {
      sendUnderlinePositionThroughPort(navId, portFun, (retryCount || 0) + 1);
    }, 100);
  }
};

const NavTabsPort = {
  add: (app) => {
    app.ports.navTabActiveChanged.subscribe((navId) => {
      // This ports usually fires before the dom refreshes, so the activeTab
      // hasn't changed yet. Thus the timeout.
      setTimeout(() => {
        sendUnderlinePositionThroughPort(
          navId,
          app.ports.onNavTabActivePositionChange,
        );
      }, 50);
    });
  },
};

module.exports = NavTabsPort;
