"use strict";

const ToastPort = {
  add: function (app, emrHost) {
    if (app.ports.sendToastMessagePort) {
      app.ports.sendToastMessagePort.subscribe(function (levelAndMessage) {
        window.parent.postMessage(
          { message: levelAndMessage[1], type: `alert.${levelAndMessage[0]}` },
          emrHost,
        );
      });
    }
  },
};

module.exports = ToastPort;
