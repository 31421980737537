"use strict";

import * as Bowser from "bowser";

const bowserUnsupported = Bowser.msie && Bowser.version <= 9;

module.exports = {
  loadIfBowserSupported: function (callback) {
    if (bowserUnsupported) {
      document.getElementById('browser-warning').style.display = 'block';
    } else {
      callback();
    }
  }
}