"use strict";

import whitelabelUrl from "./whitelabel-url";

const isLocalHref = (link) => link.host == location.host;

const urlChanged = (callback) => {
  setTimeout(() => {
    callback(location.href);
  }, 0);
};

const NavigationPort = {
  add: function (app) {
    // Inform app of browser navigation (the BACK and FORWARD buttons)
    window.addEventListener("popstate", function () {
      urlChanged(app.ports.onUrlChange.send);
    });

    if (app.ports.pushUrl) {
      app.ports.pushUrl.subscribe((url) => {
        history.pushState({}, "", whitelabelUrl.get(url));
        urlChanged(app.ports.onUrlChange.send);
      });
    }

    if (app.ports.navigationLoad) {
      app.ports.navigationLoad.subscribe((url) => {
        window.location = url;
      });
    }

    // Intercept link clicks to add custom elm logic
    document.addEventListener("click", (event) => {
      const { target } = event;

      if (target.localName != "a") {
        return;
      }

      if (target && target.href && app.ports.navigationPortOnUrlRequest) {
        const shouldOpenInNewWindow =
          target.target == "_blank" ||
          event.metaKey ||
          event.ctrlKey ||
          event.shiftKey;

        if (!shouldOpenInNewWindow) {
          event.preventDefault();
        }

        app.ports.navigationPortOnUrlRequest.send([
          target.href,
          isLocalHref(target),
          shouldOpenInNewWindow,
        ]);
      }
    });

    if (app.ports.navigationPortRequestUrl) {
      app.ports.navigationPortRequestUrl.subscribe((args) => {
        app.ports.navigationPortOnUrlRequest.send(args);
      });
    }

    if (app.ports.navigationPortOpenInNewWindow) {
      app.ports.navigationPortOpenInNewWindow.subscribe((url) => {
        const newPage = window.open("http://hint.com", "_blank");
        newPage.location = url;
      });
    }

    if (app.ports.backPort) {
      app.ports.backPort.subscribe(() => {
        history.back();
      });
    }

    if (app.ports.replaceUrl) {
      app.ports.replaceUrl.subscribe((url) => {
        history.replaceState({}, "", whitelabelUrl.get(url));
      });
    }
  },
};

module.exports = NavigationPort;
