"use strict";

const setup = (appId) => {
  if (appId) {
    window.setupCanny();
  }
};

const CannyPort = {
  add: function (app, appId) {
    setup(appId);

    app.ports.cannyIdentifyPort.subscribe((args) => {
      if (!window.Canny) {
        return;
      }

      const user = args[0];
      const redirect = args[1];

      Canny(
        "identify",
        {
          appID: appId,
          user: user,
        },
        function () {
          window.location.href = redirect;
        },
      );
    });
  },
};

module.exports = CannyPort;
