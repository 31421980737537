"use strict";

const DropdownPort = {
  add: (app) => {
    document.addEventListener("focusout", (event) => {
      const dropdown = event.target.closest(".new-dropdown");

      const blurredOutAll = (dropdown) => {
        // Recursively go up the parent nodes to close
        // All open dropdowns.
        // This is mostly helpful for dropdowns inside dropdowns.
        while (dropdown) {
          app.ports.onDropdownBlur.send(dropdown.id);
          dropdown = dropdown.parentElement.closest(".new-dropdown");
        }
      };

      if (dropdown) {
        if (!event.relatedTarget) {
          // Close all dropdowns if no new target.
          blurredOutAll(dropdown);
        } else {
          const newFocusedDropdown =
            event.relatedTarget.closest(".new-dropdown");

          if (!newFocusedDropdown) {
            // Close all dropdowns if new target is not inside a dropdown.
            blurredOutAll(dropdown);
            return;
          }

          if (
            newFocusedDropdown.classList.contains(
              "new-dropdown-menu__item--inner",
            )
          ) {
            if (newFocusedDropdown.closest(`#${dropdown.id}`)) {
              // If the new focused dropdown is an inner dropdown of
              // The previously focused dropdown do nothing.
              return;
            }
          }

          if (newFocusedDropdown.id !== dropdown.id) {
            // Close only the previous focused dropdown if the
            // newly focused one is different.
            app.ports.onDropdownBlur.send(dropdown.id);
          }
        }
      }
    });

    app.ports.focusFirstDropdownOptionPort.subscribe((dropdownId) => {
      // This will be an issue if there are elements but all of them are non visible.

      const dropdown = document.getElementById(dropdownId);
      const firstItem = dropdown.querySelector(".new-dropdown-menu__item");

      if (firstItem) {
        if (canFocus(firstItem)) {
          firstItem.focus();
          return;
        }

        focusNextFocusableItem(firstItem);
      }
    });

    app.ports.focusNextDropdownOptionPort.subscribe(() => {
      const focusedOption = document.activeElement;
      const actualOption = focusedOption.closest(".new-dropdown-menu__item");
      if (!actualOption) {
        return;
      }

      focusNextFocusableItem(actualOption);
    });

    app.ports.focusPrevDropdownOptionPort.subscribe(() => {
      const focusedOption = document.activeElement;
      const actualOption = focusedOption.closest(".new-dropdown-menu__item");
      if (!actualOption) {
        return;
      }

      focusPreviousFocusableItem(actualOption);
    });

    app.ports.focusInnerDropdownOptionInputPort.subscribe(() => {
      const focusedOption = document.activeElement;
      const actualOption = focusedOption.closest(".new-dropdown-menu__item");
      const input = actualOption.querySelector("input");

      if (input) {
        input.focus();
      }
    });

    app.ports.focusInnerDropdownOptionLinkPort.subscribe(() => {
      const focusedOption = document.activeElement;
      const actualOption = focusedOption.closest(".new-dropdown-menu__item");
      const link = actualOption.querySelector("a");

      if (link) {
        link.focus();
      }
    });
  },
};

const focusPreviousFocusableItem = (element) => {
  let previousElement = element.previousElementSibling;

  while (previousElement != element) {
    if (previousElement) {
      if (canFocus(previousElement)) {
        previousElement.focus();
        return;
      }

      previousElement = previousElement.previousElementSibling;
    } else {
      previousElement = element.parentElement.lastElementChild;
    }
  }
};

const canFocus = (item) => {
  return (
    item.classList.contains("new-dropdown-menu__item--focusable") &&
    getComputedStyle(item).display != "none"
  );
};

const focusNextFocusableItem = (element) => {
  let nextElement = element.nextElementSibling;

  while (nextElement != element) {
    if (nextElement) {
      if (canFocus(nextElement)) {
        nextElement.focus();
        return;
      }

      nextElement = nextElement.nextElementSibling;
    } else {
      nextElement = element.parentElement.firstElementChild;
    }
  }
};

module.exports = DropdownPort;
