"use strict";

const IntercomPort = {
  add: function (app, appId) {
    if (appId && window.parent === window) {
      window.setupIntercom(appId);
    }

    app.ports.intercomPortOpen.subscribe(() => {
      if (!window.Intercom) {
        return;
      }

      window.Intercom("showNewMessage");
    });

    app.ports.intercomPortBoot.subscribe((payload) => {
      if (!window.Intercom) {
        return;
      }

      window.Intercom("boot", payload);
    });

    app.ports.intercomPortShutdown.subscribe(() => {
      if (!window.Intercom) {
        return;
      }

      window.Intercom("shutdown");
    });

    app.ports.intercomPortShow.subscribe(() => {
      if (!window.Intercom) {
        return;
      }

      window.Intercom("show");
    });

    app.ports.intercomPortUpdate.subscribe(() => {
      if (!window.Intercom) {
        return;
      }

      window.Intercom("update", {});
    });
  },
};

module.exports = IntercomPort;
