"use strict";

const {
  Chart,
  BarController,
  BarElement,
  CategoryScale,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Filler,
  Tooltip,
} = require("chart.js");
const ColorHash = require("color-hash").default;
const {
  loadChartjsPluginEmptyOverlay,
} = require("../../lib/chartjs-plugin-empty-overlay.js");

Chart.register(
  BarController,
  BarElement,
  CategoryScale,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Filler,
  Tooltip,
);

const DashboardChartPort = {
  add: function (app) {
    loadChartjsPluginEmptyOverlay(Chart);

    app.ports.mountDashboardChart.subscribe(({ options, labels, datasets }) => {
      const parent = document.getElementById("dashboard-chart");

      parent.innerHTML = "";

      const ctx = document.createElement("canvas");
      parent.appendChild(ctx);

      new Chart(ctx, {
        type: "bar",
        options: formatOptions(options, datasets),
        data: {
          labels,
          datasets: formatDatasets(datasets),
        },
      });
    }, 100);
  },
};

const formatOptions = (options, datasets) => {
  if (options.scales) {
    formatScales(options.scales);
  }

  options.plugins = options.plugins || {};

  options.plugins.tooltip = formatTooltip(options, datasets);
  options.maintainAspectRatio = false;

  return options;
};

const formatTooltip = (options, datasets) => {
  const tooltip = (options.plugins && options.plugins.tooltip) || {};

  tooltip.callbacks = {};
  tooltip.callbacks.label = (item) => {
    const dataset = datasets[item.datasetIndex];
    const defaultLabel = ` ${dataset.label} - ${item.formattedValue}`;

    if (dataset.suffix) {
      return defaultLabel + dataset.suffix;
    }

    return defaultLabel;
  };

  return tooltip;
};

const formatScales = (scales) => {
  Object.values(scales).forEach((scale) => {
    if (scale.axis == "y" && scale.ticks && scale.ticks.suffix) {
      const suffix = scale.ticks.suffix;
      delete scale.ticks.suffix;

      scale.ticks.callback = (value) => value + suffix;
    }
  });
};

const formatDatasets = (datasets) => {
  return datasets.map((dataset) => {
    if (dataset.backgroundColor) {
      return dataset;
    }

    const color = colorByLabel(dataset.label);
    dataset.backgroundColor = color;
    dataset.borderColor = color;

    return dataset;
  });
};

const colorByLabel = (label) => {
  switch (label) {
    case "employer":
      return "rgba(115, 153, 245, 0.45)";
    case "retail":
      return "rgba(58, 68, 158, 0.45)";
    default:
      return `rgba(${new ColorHash({
        hash: "bkdr",
        saturation: [0.65, 0.35, 0.5],
        lightness: [0.65, 0.35, 0.5],
      })
        .rgb(label)
        .join(",")}, 0.45)`;
  }
};

module.exports = DashboardChartPort;
