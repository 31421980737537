"use strict";

import { jwtDecode } from "jwt-decode";

const isSafari = window.safari !== undefined;
let customPolicy = CONFIGURATION.debugOrTest
  ? { sameSite: "none", secure: true }
  : {};
if (isSafari && CONFIGURATION.debugOrTest) {
  delete customPolicy["secure"];
}
const keyName = "hint.session-token.provider";
let clockOffsetMs = 0;

module.exports = {
  set: function (value) {
    clockOffsetMs = jwtDecode(value).iat * 1000 - Date.now();
    return Cookies.set(keyName, value, customPolicy);
  },
  load: function () {
    return Cookies.get(keyName);
  },
  remove: function () {
    return Cookies.remove(keyName, customPolicy);
  },
  isExpired: function () {
    let decoded = jwtDecode(Cookies.get(keyName));
    const refreshInAdvanceMs = 5000;
    if (
      Date.now() + clockOffsetMs + refreshInAdvanceMs >=
      decoded.exp * 1000
    ) {
      return true;
    }
    return false;
  },
};
