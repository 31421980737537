"use strict";

const setup = function (googleMapsKey) {
  window.setupGoogleMaps(googleMapsKey);
};

const GooglePlacesPort = {
  add: function (app, googleMapsKey) {
    if (!googleMapsKey) {
      throw new Error("Google Maps Key is not configured.");
    }

    setup(googleMapsKey);

    app.ports.googlePlacesSetupAddressAutoCompletePort.subscribe(function (id) {
      // Cross browser support
      const requestAnimationFrame =
        window.requestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.msRequestAnimationFrame;

      requestAnimationFrame(function () {
        const element = document.getElementById(id);

        var gPlace = new google.maps.places.Autocomplete(element, {
          types: [],
        });
        google.maps.event.addListener(gPlace, "place_changed", function () {
          const place = gPlace.getPlace();

          // utf offset is depracted, we don't want to stringify it.
          delete place["utc_offset"];

          const address = JSON.stringify(place);

          app.ports.googlePlacesPlaceChangedPort.send({ id, address });
        });
      });
    });
  },
};

module.exports = GooglePlacesPort;
