"use strict";

import { storageFactory } from "storage-factory";

const storage = storageFactory(() => localStorage);

const LocalStoragePort = {
  getItem: storage.getItem,
  setItem: storage.setItem,
  removeItem: storage.removeItem,
  add: function (app) {
    app.ports.localStorageSetItemPort.subscribe(function (keyAndValue) {
      storage.setItem(keyAndValue[0], keyAndValue[1]);
    });

    app.ports.localStorageGetItemPort.subscribe(function (key) {
      const value = storage.getItem(key);
      app.ports.localStorageGotItemPort.send([key, value]);
    });
  },
};

module.exports = LocalStoragePort;
