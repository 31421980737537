"use strict";

const ClipboardPort = {
  add: function (app) {
    app.ports.clipboardCopyPort.subscribe(function (text) {
      const textareaToCopyFrom = document.createElement("textarea");
      window.document.body.appendChild(textareaToCopyFrom);
      textareaToCopyFrom.value = text;
      textareaToCopyFrom.select();
      window.document.execCommand("copy");
      window.document.body.removeChild(textareaToCopyFrom);
    });
  },
};

module.exports = ClipboardPort;
