class ApiService {
  constructor(url) {
    this.url = url;
  }

  async deleteSession(token) {
    this.sessionRequest(token, ()=>{}, "DELETE");
  }

  async createSession(token, callback) {
    this.sessionRequest(token, callback, "POST");
  }

  async sessionRequest(token, callback, method) {
    const authorizationHeader = `Bearer ${token}`;
    const url = `${this.url}session`;
    try {
      const response = await fetch(url, {
        method: method,
        headers: { "Authorization": authorizationHeader },
      });
      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }
      callback(response);
    } catch (error) {
      console.error(error.message);
    }
  }
}


export { ApiService }
