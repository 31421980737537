"use strict";

const AmplitudePort = {
  add: function (app, key) {
    if (key) {
      window.setupAmplitude(key);
    }

    app.ports.amplitudePortLogEvent.subscribe(function (event) {
      if (!window.amplitude) return;

      const options = event.options || {};
      window.amplitude.getInstance().logEvent(
        event.name,
        Object.assign(
          {
            app: event.app,
            url: event.url || location.pathname,
          },
          options,
        ),
      );
    });

    app.ports.amplitudePortBoot.subscribe(function (userIdAndUserAttrs) {
      if (!window.amplitude) return;

      const userId = userIdAndUserAttrs[0];
      const userAttrs = userIdAndUserAttrs[1];

      window.amplitude.getInstance().setUserId(userId);
      window.amplitude.getInstance().setUserProperties(userAttrs);
    });

    app.ports.amplitudePortShutdown.subscribe(function () {
      if (!window.amplitude) return;

      window.amplitude.getInstance().setUserId(null);
      window.amplitude.getInstance().regenerateDeviceId();
    });
  },
};

module.exports = AmplitudePort;
